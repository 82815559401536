import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import ReactJson from "@microlink/react-json-view"
import { dvSrv } from "../App"
import { SessionContext } from '../App';
import { TeamSelector } from './Team';
import { SearchableUserSelector } from './User';
import moment from 'moment-timezone';
import DataProvider, { useActiveTemplate, useTeamAndUser } from '../hook/DataProvider';
import { TimeStringForTimeZone } from "./Setting"
import { toast } from "react-toastify"
import { ReportImage } from './ReportImage';
import { GetUserFullname } from "./User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from 'react-tooltip'
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from 'recharts';

export const Reports = () => {
  const session = useContext(SessionContext)
  const [reports, setReports] = useState([]) 

  useEffect(() => {
    axios.get(dvSrv + "/api/report/", {withCredentials: true})
    .then(({data}) => {
      setReports(data)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }, [])

  let fieldReps = []
  let attReps = []
  for(const report of reports){
    // console.log("report: ", report)
    if (/*report.Attempt === 0*/report.TemplateType === "Field_Report"){
      fieldReps.push(report)
    } else if(/*report.Attempt > 0*/report.TemplateType === "Attempt_Report"){
      attReps.push(report)
    }
  }

//  console.log("fieldReps: ", fieldReps)
//  console.log("attReps: ", attReps)
  return (
    <>
    <div class="row">
      <ul class="nav nav-tabs" id="reportsTabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="field-reports-tab" data-bs-toggle="tab" data-bs-target="#fieldreports" type="button" 
          role="tab" aria-controls="fieldreports" aria-selected="false">Field Reports</button>
        </li>
        { /*(data.IsAdmin || data.IsRoot) &&*/ <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="att-reports-tab" data-bs-toggle="tab" data-bs-target="#attreports" type="button" 
          role="tab" aria-controls="attreports" aria-selected="false">Attempt Reports</button>
        </li>}

      </ul>
      <div class="tab-content" id="reportsContents">
        <div class="tab-pane fade show active" id="fieldreports" role="tabpanel" aria-labelledby="field-reports-tab">
          <ReportPage tname="ReportTemplate" data={fieldReps}/>
        </div>
        <div class="tab-pane fade" id="attreports" role="tabpanel" aria-labelledby="att-reports-tab">
          <ReportPage tname="AttemptTemplate" data={attReps} />
        </div>
      </div>
    </div>
    {/* <ReactJson src={users} collapsed={1} /> */}
    </>
  )

}

export const ReportPage = ({tname, data}) =>{
  const template = useActiveTemplate(tname)
  // console.log("data", data)
  // const isAttempt = tname === "AttemptTemplate"

  let total = data.length
  const average = (id) => {
    let sum = 0

    data.forEach(report => {
        for (const v of report.FieldValues) {
            // console.log("field value array, v.Value, val ", dvs, v.Value, val)
            if (v.FieldID === id){              
                sum += v.Value
                break
            }        
        }
    })
    // console.log("sum, total ", sum, total)
    return parseFloat(sum/total).toFixed(2)
  }

  const percent = (id, val) => {
    let sum = 0

    data.forEach(report => {
        for (const v of report.FieldValues) {
            // console.log("field value array, v.Value, val ", dvs, v.Value, val)
            if (v.FieldID === id){
              let dvs = v.FieldValueList.split(',')
              if (v.Value > 0 && dvs[v.Value-1]===val){
                sum += 1
                break
              }
            }        
        }
    })
    // console.log("sum, total ", sum, total)
    if (sum > 0) return parseFloat(sum/total*100).toFixed(0)/*+"%"*/
  }

  if (data && template)
  return (
    <>
    {/* <h2>{isAttempt ? "Attempt" : "Field"} Reports</h2> */}
    <table class="table table-hover">
      <thead>
        <th>#</th>
        <th class="text-center"></th>
        <th>Description</th>
        {/* <th>Options</th> */}
        <th class="text-center">% of {total} reports</th>
      </thead>
      <tbody>
        {/* {data && data.map(report => <ReportTab report={report} />)} */}

        {template.TemplateFields?.map(field => 
        <tr>
          <td>{field.Number}</td>
          <td class="text-center">
            <input class="form-check-input" type="checkbox"
              checked={field.Enabled} />
          </td>
          <td>{field.Description}</td>
          {/* <td>{field.FieldValues?.split(',').map((val, id) => <p>{val}: {percent(field.ID, id)}</p>)}</td> */}
          <td width={400} class="text-center">
            {field.FieldType === 'text' ? "N/A" 
             : field.FieldType === 'number' ? "average " + average(field.ID) : <ResponsiveContainer width="100%" height={100}>
              <BarChart data={field.FieldValues?.split(',').map((val) => ({cat: percent(field.ID,  val), name: val}))}
                margin={{ top: 20, right: 20, left: 20, bottom: 5 }}>
                <XAxis dataKey="name" />
                <Bar dataKey="cat" fill='#8884d8' isAnimationActive={false}>
                  <LabelList dataKey="cat" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>}
          </td>
        </tr>)}

      </tbody>
    </table>
    {/* <ReactJson src={{template, data}} /> */}
    </>
  )
}

export const ReportTab = ({report}) => {
  const session = useContext(SessionContext)

  if (report && report.FieldValues)
  return (
    <>
    <tr>
      <td></td>
      <td>{report.CaseNumber}</td>
      <td>{TimeStringForTimeZone(session, report.Date)}</td>
      <td>{report.TeamID}</td>
      {report.FieldValues.map(v => 
        <td>
          {v.Value}
        </td>
      )}
    </tr>
    </>
  )
}

// export const AirwayReport = ({caseNumber}) => {
//   const session = useContext(SessionContext)
//   const [report, setReport] = useState()
//   const [teams, users] = useTeamAndUser()

//   useEffect(() => {
//     axios.get(dvSrv + `/api/airwayreport/${caseNumber}`, {withCredentials: true})
//     .then(({data}) => {
//       setReport(data)
//     })
//     .catch(error => {
//       if (error.response && error.response.status === 401) {
//           session.setData(null)
//       } else {console.error("Error: ", error)}
//     })}, [caseNumber])  
    
//   const onSave = (e) => {
//     axios.post(dvSrv + `/api/airwayreport/${caseNumber}`, report, {withCredentials: true})
//     .then(({data}) => {
//       setReport(data)
//     })
//     .catch(error => {
//       if (error.response && error.response.status === 401) {
//           session.setData(null)
//       } else {console.error("Error: ", error)}
//     })}

//   const onValueChange = (e) => {
//     const {name, value} = e.target
//     setReport({...report, [name]: parseInt(value, 10)})
//   }
//   const onCommentsChange = (e) => {
//     const {name, value} = e.target
//     setReport({...report, ["Comments"]: value})
//   }

//   const editor = report && report.UserID && users.find(user => user.ID === report.UserID)

//   if (teams && users && report)
//   return (    
//     <>
//       <div class="row">
//         <div class="col-12">
//           <div class="card mb-3">
//             <h4 class="card-header text-center">Intubation Report</h4>
//             <div class="card-body">
//               <h5 class="card-title text-center mb-3">Case #{caseNumber}</h5>
//               {report.UserID && (users.length > 0) &&
//               <h6 class="card-subtitle text-body-secondary text-center mb-5">
//                 Last edited: {moment(report.UpdatedAt).fromNow()} - by {editor && 
//                 <span>{editor.FirstName} {editor.LastName}</span>}
//               </h6>}
//               <div class="row">
//               <div class="col-6 mb-3">
//                 <label class="fw-bold form-label">Provider</label>
//                 <UserSelector users={users} data={report} setData={setReport} />
//               </div>
              
//               <div class="col-6 mb-3">
//                 <label class="fw-bold form-label">Team</label>
//                 <TeamSelector teams={teams} data={report} setData={setReport} />
//               </div>
//               </div>

//               <h5 class="card-title text-center my-3">Intubation</h5>

//               <RadioButtonGroup title="Type of patient" name="PatientType" value={report.PatientType} onValueChange={onValueChange} labels={["Trauma", "Medical"]} />

//               <RadioButtonGroup title="LMA placed?" name="LMAPlaced" value={report.LMAPlaced} onValueChange={onValueChange} labels={["Yes", "No"]} />

//               <RadioButtonGroup title="Suction utilized?" name="Suction" value={report.Suction} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="Blade midline?" name="Midline" value={report.Midline} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="Vocal cords visualized?" name="VocalCordsVisualized" value={report.VocalCordsVisualized} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="50/50 view?" name="FiftyFiftyView" value={report.FiftyFiftyView} onValueChange={onValueChange} colors={["success","danger","secondary"]} />

//               <RadioButtonGroup title="View maintained for ETT placement?" name="ViewMaintained" value={report.ViewMaintained} onValueChange={onValueChange} labels={["Yes", "No"]} colors={["success","danger"]} />

//               <RadioButtonGroup title="Stylet or Bougie?" name="Stylet" value={report.Stylet} onValueChange={onValueChange} labels={["Stylet", "Bougie"]} />

//               <RadioButtonGroup title="Blade?" name="Blade" value={report.Blade} onValueChange={onValueChange} labels={["Angulated", "Standard"]} />

//               <RadioButtonGroup title="Blade size?" name="BladeSize" value={report.BladeSize} onValueChange={onValueChange} labels={["0", "1", "2", "3", "4"]} />

//               <RadioButtonGroup title="Attempt?" name="Attempt" value={report.Attempt} onValueChange={onValueChange} labels={["1", "2", "3"]} colors={["success", "warning", "danger"]}/>

//               <RadioButtonGroup title="Intubation successful?" name="Successful" value={report.Successful} onValueChange={onValueChange} labels={["Yes", "No"]} colors={["success", "danger"]}/>

//               <div class="form-floating mb-3">
//                 <textarea 
//                   name='review'
//                   id='textinput-review'
//                   class="form-control" 
//                   style={{ height: '200px' }} 
//                   value={report.Comments} 
//                   onChange={onCommentsChange} 
//                   placeholder="Video Review"
//                 />
//                 <label for="textinput-review">Comments</label>
//               </div>
//               <button onClick={onSave} type="submit" class="btn btn-primary">Save</button>
//               {/* <a href="#" class="btn btn-primary">Save</a> */}
//             </div>
//             <div class="card-footer text-muted">
//               Report has been locked. Submit comments here.
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <ReactJson src={{caseNumber, report, users, teams}} collapsed="0" />     */}
//     </>
//   )
// }

export const RadioButtonGroup = ({title, name, value, onValueChange, labels=["Yes","No","N/A"], colors=["secondary"]}) => {

  return (
    <div class="row mb-3">
      <label class="fw-bold form-label">{title}</label>
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
            <input type="radio" class="btn-check" name={name} id={`${name}-${i+1}`}
              value={i+1} checked={value === i+1} onChange={onValueChange} />
            <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
              for={`${name}-${i+1}`}>{label}</label>
          </>
        )}
      </div>
    </div>
  )
}

// export const CaseReportAndReview = ({casenum, type}) => {

//   return (
//   <>
//     <div class="row">
//       <div class="col-12"> 
//       {/* Field Report */}
//         <CaseReport casenum={casenum}
//                   type = {type}
//                   attUrl={dvSrv + "/api/setting/" + type} 
//                   getpostVideoUrlRoot={dvSrv + "/api/video/"}
//                   getpostCaseUrl={dvSrv + "/api/case/" + casenum +"/" + type}
//                   updateUrl={dvSrv + "/api/" + type + "/"} />
//       </div>
//     </div>
//   </>
//   )
// }

export const RadioButtonGroupForField = ({attempt, field, data, onValueChange}) => {
  const baseName = `att_${attempt}_${field.FieldName}`
  // use the original value to figure out the index in the field.FieldValues because it may have
  // changed from the template
  // console.log("field: ", field )
  // console.log("data: ", data)
  let oldV = ""
  let index = -1
  if (field?.FieldValues !== data?.FieldValueList && data?.Value>0) {
    let fvs = field.FieldValues.split(',')
    let dvs = data.FieldValueList.split(',')
    index = fvs.indexOf(dvs[data.Value-1])
    // if old FieldValue is found in new template FieldValues, 
    // let's update the old FieldValueList
    if (index>=0) {
      onValueChange(field.ID, field.Description, field.FieldValues, index+1)
    } else{ // the old value is not in new template FieldValues anymore
      oldV = dvs[data.Value-1]
    }
  } else if (data){ index = data.Value-1}

  let val = index >=0 ? index+1 : 0
  // console.log("oldV val: ", oldV, val)
  if(field)
  return (
<>
    <RadioButtonGroupPlus id={field.ID} name={baseName} fromProvider={field.FromProvider}
                      type={field.FieldType} description={field.Description}
                      vals={field.FieldValues} val={val} oldV={oldV}
                      onValueChange={onValueChange} />
{/* <ReactJson src={{field, data}} collapsed={1} /> */}
</>
)

}

export const RadioButtonGroupPlus = ({id, name, fromProvider, type, description, vals, val, oldV, onValueChange}) => {

  const onChange = (e) => {
    let v = parseInt(e.target.value, 10)
    onValueChange(id, description, labels.join(','), v)
  }

  let labels, colors = ["secondary"]
  
  switch (type) {
    case 'bool':
      labels = ["Yes","No"]
      colors = ["success", "danger"]
      break      
    case 'bool+':
      labels = ["Yes","No", "N/A"]
      colors = ["success", "danger", "secondary"]
      break
    case 'string':
    case 'int':
    default:
      labels = vals.split(',')
      break
  }

  return (
    <>
    <div class="row mb-3">
    {fromProvider 
    ? <label class="fw-bold form-label"><FontAwesomeIcon icon={faUserCheck} id="icon-provider-input"/>&nbsp;&nbsp;{description}&nbsp;&nbsp;{oldV==="" ? <span /> : <span class="bg-warning">(last saved value: {oldV})</span>}</label>
    : <label class="fw-bold form-label">{description}&nbsp;&nbsp;{oldV==="" ? <span /> : <span class="bg-warning">(last saved value: {oldV})</span>}</label>}
    
      <div class="btn-group col-12">
        {labels.map((label, i) => 
          <>
          <input 
            type="radio" 
            class="btn-check" 
            name={name} 
            id={name+(i+1)}
            value={i+1}
            checked={val === i+1}
            onChange={onChange} 
          />
          <label class={`btn btn-outline-${colors.length === 1 ? colors[0] : colors[i]}`}
            for={name+(i+1)}>{label}</label>
          </>
        )}
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    <Tooltip anchorSelect="#icon-provider-input" place="right">Check with the provider</Tooltip>
    </>
  )
}

export const InputBoxGroupForField = ({attempt, field, data, onValueChange}) => {
  const baseName = `att_${attempt}_${field.FieldName}`
  const inputType = field?.FieldType==='number' || field?.FieldType==='text' ? field?.FieldType : ''

  const onInputChange = (e) => {
    onValueChange(field.ID, field.Description, "", 
      inputType==='number'? parseFloat(e.target.value) : e.target.value
    )
  }
  // console.log("new field, type, data ", field, inputType, data)
  if(field && inputType !== '')
  return (
    <>
    <div class="row mb-3">
      {field.fromProvider 
      ? <label class="fw-bold form-label"><FontAwesomeIcon icon={faUserCheck} id="icon-provider-input"/>&nbsp;&nbsp;{field.Description}</label>
      : <label class="fw-bold form-label">{field.Description}</label>}
      
      <div class="input-group col-12">
        <input type={inputType} id={baseName} class="col-sm-2 form-control form-control-sm mb-1" value={data?.Value} onChange={onInputChange} />
      </div>
    </div>
    {/* <ReactJson src={{field}} collapsed={1} /> */}
    <Tooltip anchorSelect="#icon-provider-input" place="right">Check with the provider</Tooltip>
    </>
  )
}


export const CaseReport = ({caseNumber, type, attempt, onSaved, update}) => {
  const session = useContext(SessionContext)
  const [teams, users] = useTeamAndUser()
  
  const [data, setData] = useState({})
  const [flag, setFlag] = useState(false)
  const refresh = () => setFlag(!flag)
  const reportImageRef = useRef(null)

  const [template, setTemplate] = useState(null)

  // const template = useActiveTemplate(attempt === 0 ? "ReportTemplate" : "AttemptTemplate")
  const [templateName, setTemplateName] = useState(type==='Field_Report' ? "ReportTemplate" : "AttemptTemplate")

  useEffect(() => {
    axios.get(dvSrv + `/api/template/`, {withCredentials: true})
    .then(({data}) => {
      setTemplate(data.find(t => t.Name === templateName))
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [templateName])

  const [reports, setReports] = useState([])
  useEffect(() => {
    if (attempt !== 0) return
    axios.get(dvSrv + `/api/report/${caseNumber}`, {withCredentials: true})
    .then(({data}) => {
        setReports(data)
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber, update])

  // console.log("template fields: ", template?.TemplateFields)
  const reportUrl = `/api/report/${caseNumber}/att/${attempt}`
  const baseLabel = `att_${attempt}`

  useEffect(() => {
    
    axios.get(dvSrv + reportUrl, {withCredentials: true})
    .then(({data}) => setData(data))
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber, update, flag])

  const onValueChange = (id, desciption, variables, value) => {
    let vs = [...data.FieldValues] // DON'T point to (ie vs = data.FieldValues), MAKE A COPY!!!
    // console.log("field ID: ", id)
    let index = vs.findIndex(v => v.FieldID === id)
    if (index === -1) vs.push({FieldID: id, FieldDescription: desciption, FieldValueList: variables, Value: value}) // new data
    else {
      vs[index].FieldDescription = desciption
      vs[index].FieldValueList = variables
      vs[index].Value = value
    }
    // console.log("vs: ", vs)
    setData({...data, FieldValues: vs})
  }  
  
  const onCommentsChange = (e) => {
    setData({...data, ["Summary"]: e.target.value})
  }

  const onSave = (e) => {
    try {

      axios.post(dvSrv + reportUrl, JSON.stringify({
        ...data,
        TemplateID: template.ID,
        TemplateType: template.Type,
        UserType: template.UserType,
        CaseNumber: caseNumber,
      }), {withCredentials: true})
      .then(({data}) => {
        setData(data)
        onSaved(type, attempt)
        toast.success("Report is saved!")
      })

      if (type==='Field_Report'){
        reportImageRef?.current?.saveImageChange()
      }

    }catch(error){
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    }
  }

  if (template && data)
  return (    
    <>
    <div class="card" id={`att_${attempt}-rep-card`}>
      {/* <h4 class="card-header text-center">Intubation Report</h4> */}

      <div class="card-body">
        <h5 class="card-title text-center">Case # {caseNumber}</h5>
        {data.UserID ? <p class="card-text">Last modified: {moment(data.UpdatedAt).fromNow()}</p> : ""}
      
        {/*##### when this is attempt report, or case report but there is no saved attempt reports yet #####*/}
        {(attempt > 0 || (attempt ===0 && reports.length < 2)) && <div class="row mb-3">
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Provider</label>
            <SearchableUserSelector users={users} data={data} setData={setData} />
          </div>      
          <div class="col-6 mb-3">
            <label class="fw-bold form-label">Co-Providers</label>
            {/* <TeamSelector teams={teams} data={data} setData={setData} /> */}
            <SearchableUserSelector users={users} data={data} setData={setData} multi={true}/>
          </div>
        </div>}
        {/*##### when there is case report and there are attempt report(s) #####*/}
        {type==='Field_Report' && reports.length > 1 && 
        reports.sort((a, b) => a.Attempt - b.Attempt).map(rep => rep.Attempt>0 &&
          <div class="row mb-3">      
            <div class="col-6 mb-3">
              <label class="fw-bold form-label">Attempt {rep.Attempt} Provider</label>
              <ul>
                  <li>{GetUserFullname(rep.ProviderID, users)}</li>
              </ul>      
            </div>      
            <div class="col-6 mb-3">
              <label class="fw-bold form-label">Attempt {rep.Attempt} Co-Providers</label>
              <ul>
                {rep.CoProviderIDs?.map(copid => (
                    <li>{GetUserFullname(copid, users)}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {/* <ReactJson src={{data}} collapsed="3" /> */}
      </div>

      <h5 class="card-title text-center mb-3">QA Measurements</h5>
      
      <div class="card-body" id={`${baseLabel}-rep-body`}>
        {template.TemplateFields && template.TemplateFields.filter(v=>v.Enabled).map(field => (field.FieldType==='number' || field.FieldType==='text')
         ? <InputBoxGroupForField attempt={attempt} field={field} data={data.FieldValues && data.FieldValues.find(v => v.FieldID === field.ID)} onValueChange={onValueChange} />
         : <RadioButtonGroupForField attempt={attempt} field={field} data={data.FieldValues && data.FieldValues.find(v => v.FieldID === field.ID)} onValueChange={onValueChange} />)
        }

        <div class="form-floating">
          <textarea 
            name='review'
            id={`${baseLabel}_summary`}
            class="form-control" 
            style={{ height: '200px' }} 
            value={data.Summary} 
            onChange={onCommentsChange} 
            placeholder="Video Review"
          />
          <label for={`${baseLabel}_summary`}>Comments</label>
        </div>
      </div>
      
      {data && data.CaseNumber && type==='Field_Report' && <ReportImage caseNum={data.CaseNumber} ref={reportImageRef} refresh={refresh}/>}

      <div class="d-grid gap-2 col-6 mx-auto card-body">
        <button onClick={onSave} type="submit" class="btn btn-primary" disabled={!session.data.ReportEdit}>Save</button>
      </div>

      {/* <div class="card-footer text-muted">
        Report has been locked. Submit new comments as Note below.
      </div> */}
    </div>
    {/* <ReactJson src={{data}} collapsed="3" /> */}
    </>
  )
}

export const CaseReview = ({casenum, type, attUrl, getpostVideoUrlRoot, getpostCaseUrl, updateUrl}) => {
  const [summary, setSummary] = useState("")
  const [FieldValues, setFieldValues] = useState({});
  const [data, setData] = useState({});
  // the review index should be currentReview - 1
  const [currentReview, setCurrentReview] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [changes, setChanges] = useState({});

  const [reviewTypes, setReviewTypes] = useState([]);
  const [selectedReviewType, setSelectedReviewType] = useState(casenum);
  const [videos, setVideos] = useState([])

  const handleSelect = (selectedValue) => {
    // Handle the selected value here
    console.log("Selected option:", selectedValue);
    resetFieldVals({})
    setData({})
    setCurrentReview(null)
    setSummary("")
    setChanges({})
    setSelectedReviewType(selectedValue)

  };

  const resetFieldVals = () =>{
    attributes
    .filter(config => config.Enabled)
    .map(config => ( FieldValues[config.FieldName] = false ))
  }

  useEffect(() => {
    axios.get(attUrl, {withCredentials: true})
    .then(({data}) => {
      setAttributes(data);
    })
    .catch(error => {
      console.error(error);
    })
  }, [attUrl])
  
  useEffect(() => {
    axios.get(dvSrv + `/api/case/${casenum}`, {withCredentials: true})
    .then(({data}) => {
      setVideos(data.Videos)
      reviewTypes.length = 0
      reviewTypes.push(casenum)

      data.Videos.map(v => {
        //if (!reviewTypes.includes(v.FileName)) {
          //setReviewTypes([...reviewTypes, v.FileName]); // Add new option if it doesn't exist
        //  setReviewTypes((prevOptions) => [...prevOptions, v.FileName]);
          reviewTypes.push(v.FileName)
        //}
      })
    })
    .catch(error => {
       console.error("Error: ", error)
    })}, [casenum])
  
  // Fetch reviews from the server on component mount
  useEffect(() => {
    const foundVideo = videos.find(video => video.FileName === selectedReviewType);
    console.log(foundVideo);
    let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
    console.log("url: ", url);
    axios.get(url, {withCredentials: true})
    .then(response => 
        {
          setData(response.data)
          if (response.data.result){
              setCurrentReview(response.data.result)
              setSummary(response.data.result.Summary)
          }

          if (response.data.fields && response.data.fields.length > 0){
              for( const val of response.data.values) {
                for (const field of response.data.fields){
                  if(field.ID === val.FieldID){
                    FieldValues[field.FieldName] = (val.Value === "true")
                    break
                }}
              }
              //setFieldValues(tagvals)
          } else { // there is no review or report yet
              resetFieldVals()
          }
        })
    .catch(error => {
      if (error.response.status === 404) {
        //resetTagVals()
      } else { console.error("Error: ", error) }
  })}, [casenum, attributes, selectedReviewType]);

  const handleCheckboxChange = (field, isChecked) => {
    //changes[field] = isChecked
    setChanges(changes => ({
      ...changes,
      [field]: isChecked
    }))
  }

  const onSaveReview = async(e) => {
    e.preventDefault();
    if (changes.length ===0){
        return;
    }
    const formNote = new FormData()
    formNote.append("Summary", summary)    
    for (const [key, value] of Object.entries(changes)) {
    //  console.log(`Key: ${key}, Value: ${value}`);
      formNote.append(key, value)
    }

    if (currentReview === null) { // new review
      const foundVideo = videos.find(video => video.FileName === selectedReviewType);
      let url = foundVideo ? (getpostVideoUrlRoot + foundVideo.ID +"/" + type) : getpostCaseUrl
      axios.post(url, formNote, {withCredentials: true})
        .then(response => {
          //setData(response.data)
          console.log(response.data);
          for( const val of response.data.values) {
            for (const field of response.data.fields){
              if(field.ID === val.FieldID){
                FieldValues[field.FieldName] = (val.Value === "true")
                break
            }}
          }
          //setFieldValues(tagvals)
          setCurrentReview(response.data.result)
          //setSummary(response.data.result.Summary)
          setChanges({})
          toast.success("Review is saved!")
        })
        .catch(error => console.error("Error posting to server: ", error))
    } else if (currentReview !== null) {
      axios.put(updateUrl+`${currentReview.ID}`, formNote, {withCredentials: true})
        .then(response => {
          setChanges({})
          //setReviews(reviews.map(review => review.ID === currentReview.ID ? { ...review, ...response.data } : review));
          for (const [key, value] of Object.entries(response.data)) {
            //  console.log(`Key: ${key}, Value: ${value}`);
              if (key === "Summary") {
                currentReview.Summary = value
              } else{
                FieldValues[key] = (value === "true")  
              }
            }
            //setData(response.data)
            toast.success("Review is saved!")
        })
        .catch(error => console.error("Error saving result: ", error))
    }
  }

  return (
    
    <>
    <div class="row">

        <ReviewTypeSelector options={reviewTypes} onSelect={handleSelect} />

        {attributes.filter(config => config.Enabled).map((config) => (
        <div key={config.FieldName} class="form-check my-2 align-items-center">
          <input 
            class="form-check-input"
            type="checkbox" 
            checked={config.FieldName in changes ? changes[config.FieldName] : FieldValues[config.FieldName]}
            onChange={(e) => handleCheckboxChange(config.FieldName, e.target.checked)}
          />
          <span>{config.Description}</span>
        </div>
      ))}

      <div class="col-12">
        <div class="form-floating">
          <textarea 
            name='review'
            id='textinput-review'
            class="form-control" 
            style={{ height: '400px' }} 
            value={summary} 
            onChange={e => setSummary(e.target.value)} 
            placeholder="Video Review"
          />
          <label for="textinput-review">Summary</label>
        </div>


      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-6">
        <button onClick={onSaveReview} type="submit" class="btn btn-secondary">Save</button>
      </div>
    </div>
    {/* <ReactJson src={{FieldValues}} collapsed="0" />
    <ReactJson src={{changes}} collapsed="0" />
    <ReactJson src={{currentReview}} collapsed="1" />
    <ReactJson src={{data}} collapsed="0" /> */}
    {/* { <ReactJson src={{changes}} collapsed="1" />}
    { <ReactJson src={{FieldValues}} collapsed="1" />}
    {  <ReactJson src={{data}} collapsed="1" />}
    { <ReactJson src={{selectedReviewType}} collapsed="0" />} */}
    
  </>
  )
}


export const TemplateSelector = ({id, teams, data, setData, options, onSelect}) => {

  const handleSelect = (e) => {
    onSelect(parseInt(e.target.value))
  }

  // if (teams)
  // return (
  //   <select class="form-select" name="teamAssigned"
  //     value={data.TeamID || ""}
  //     onChange={handleSelect}>
  //       <option selected>Pick a team</option>
  //       {teams.map(item => <option value={item.ID}>{item.Name}</option>)}
  //   </select>
  // )

  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Template:</label>
      <select value={id} onChange={handleSelect}>
        <option selected>Pick a template</option>
        {options.map((option, index) => (
          <option key={index} value={option.ID}>
            {option.Name}
          </option>
        ))}
      </select>
    </div>
  );
}

export const ReviewTypeSelector = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]); // Initialize with the first option

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); // Call the callback function with the selected value
  };

  return (
    <div>
      <label class="form-label fw-bold me-3">Select Case or Video:</label>
      <select value={selectedOption} onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
